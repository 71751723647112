/* .player2-game-board .lane {
  flex-direction: row;
}
.lane {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}
.tile {
  margin: 10px;
}
.board {
  display: flex;
  justify-content: center;
}
.dice {
  width: 5rem;
}
.dice-roll {
  width: 3rem;
}
.player1-game-board .lane {
  margin: 0 0 10px 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-right: 30px;
}
.player2-game-board .lane {
  margin: 0 0 10px 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.score-lane {
  margin: 0 20px;
  font-weight: 500;
}
.dice-container {
  width: 5rem;
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dice-container span {
  font-size: 30px;
}
.dice-container span:hover {
  transform: scale(1.1);
}
.lane span {
  width: 3rem;
  text-align: center;
}
.reset-game {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
}
.reset-game-button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.reset-game-button:hover {
  background-color: #ff6528;
  color: white;
} */

.reset-game {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
}
.reset-game-button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.reset-game-button:hover {
  background-color: #ff6528;
  color: white;
}
