//- colors
$color-you: #006db4;
$color-opponent: #ff6528;

//- mixins
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin frosted-light {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(200px);
}
@mixin frosted-dark {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(200px);
}

.homepage {
  scroll-behavior: smooth;
  .pattern-background {
    z-index: -1;
  }
  main {
    padding: 1rem;
  }
  section.highlighted {
    margin: 0em -3em;
    padding: 1px 2em 1em;
    background: lightyellow;
    border: 0.5em solid $color-you;
  }
  g.p-mask-g {
    transform: translateX(100%);
    will-change: transform;
    transition: transform 0s !important;
  }
  .homepage-header {
    @include center;
    flex-direction: column;
    color: $color-you;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 0.75rem;
    padding: 2rem;
    text-transform: uppercase;
    h1 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0.25em;
    }
    p {
      text-align: center;
      font-size: 1.25rem;
    }
    @media screen and (min-width: 540px) {
      br {
        display: none;
      }
    }
  }
  .homepage-create-game-link {
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 1.75rem;
    border-radius: 2rem;
    padding: 1rem 2rem;
    margin: scaleLength(12) 0;
    background: $color-you;
    color: white;
    border: 1px solid white;
    will-change: background-position;
    background: linear-gradient(
      135deg,
      $color-you 0%,
      $color-you 45%,
      lighten($color-you, 20%) 50%,
      $color-you 55%,
      $color-you 100%
    );
    background-size: 300% 300%;
    background-repeat: no-repeat;
    @media not all and (prefers-reduced-motion) {
      animation: shimmer 5s linear infinite;
      margin: 1rem 0;
    }
    &:hover,
    &:focus {
      border: 1px solid darken($color-you, 20%);
      background: darken($color-you, 20%);
    }
  }
  .homepage-article {
    background: white;
    border-radius: 0.75rem;
    color: #222;
    padding: 2rem 2rem 2rem 2rem;
    h3 {
      font-size: 1.2rem;
      margin: 1.5em 0 0.6em;
    }
    p {
      font-weight: 400;
      font-size: 1rem;
      margin: 0 0 0.6em 0;
      line-height: 1.5;
    }
  }
  .labs-logo {
    margin-top: 1.25em;
    max-width: 100%;
    display: inline-block;
    svg {
      max-width: 100%;
    }
  }
  @media screen and (min-width: 900px) {
    main {
      padding: 1px 0;
    }
    .homepage-header {
      position: fixed;
      display: block;
      background: none;
      padding: 3.6rem 1.5rem;
      color: white;
      h1 {
        font-size: 3rem;
        margin-bottom: 0;
      }
      p {
        text-align: left;
      }
    }
    .homepage-create-game-link {
      position: fixed;
      border: none;
      background: white;
      color: $color-you;
      bottom: 0;
      margin: 2rem;
      cursor: pointer;
      &:hover,
      &:focus {
        color: white;
        border: none;
      }
    }
    .homepage-article {
      max-width: 512px;
      margin: 4rem 1.5rem 1.5rem
        calc(100vw - 1.5rem - min(512px, calc(50vw - 3rem)));
    }
  }
  @media screen and (min-width: 1200px) {
    .homepage-header {
      padding: 0 4rem;
      h1 {
        font-size: 4rem;
      }
    }
    .homepage-article {
      max-width: 512px;
      margin: 6rem 4rem 4rem calc(100vw - 4rem - min(512px, calc(50vw - 8rem)));
    }
    .homepage-create-game-link {
      margin: 4rem;
    }
  }
  .redirect-user {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .homepage-header {
      color: $color-you;
      background: rgba(255, 255, 255, 0.95);
      border-radius: 0.75rem;
      padding: 2rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 10;
      h1 {
        font-size: 3rem;
        margin-bottom: .7rem;
      }
    }
    .dialog-button {
      margin-top: 1rem;
    }
  }
}
